//@author: devin
import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { Cache } from "../decorators/cache.decorator";
import { jSith } from "../util/jquery-replacement";

export class LynxService {
    @Cache()
    public static getUserInstances() {
        return DataAccess.get("/lynx/user/instances.json").then(
            (resp) => {
                return (resp && resp.inst) || [];
            },
            (err) => {
                return [];
            },
        );
    }

    @Cache()
    public static getInstances(): Promise<{ name: string }[]> {
        return DataAccess.get("/lynx/all/instances.json").then(
            (resp) => {
                return S25Util.array.forceArray(resp?.list?.item);
            },
            (err) => {
                return [];
            },
        );
    }

    @Cache()
    public static getExpSecEnabled() {
        return DataAccess.get("/lynx/exp_sec_enabled.json").then(
            (resp) => {
                return S25Util.toBool(resp && resp.expSecEnabled && resp.expSecEnabled.val);
            },
            (err) => {
                return false;
            },
        );
    }

    /**
     *
     * @param method eg, post
     * @param pathAndParams eg, /imex.json?cmd=pause
     * @param payload eg, { uid: [ 'LYNX-123' ] }
     * @description Call every lynx instance the user can access and attempt the method w/ params and payload. Return the first success; else return all errors.
     */
    public static callLynx(method: string, pathAndParams: string, payload?: any) {
        let promises: Promise<any>[] = [];
        return LynxService.getUserInstances().then((instances) => {
            jSith.forEachObj(instances, (inst) => {
                promises.push(LynxService.callLynxInstance(inst, method, `${pathAndParams}`, payload));
            });

            return S25Util.any(promises);
        });
    }

    public static callLynxInstance(instance: string, method: string, pathAndParams: string, payload?: any) {
        const host = window.location.hostname;
        const protocol = window.location.protocol;
        //To manage lynx authentication for local development call current location a proxy like this is needed
        //	Header edit Set-Cookie "^(WSSESSIONID=[^;]*)(|; Domain=[^;]*)(|; path=[^;]*)(/)"  "$1$2$3"
        // 	Header edit Set-Cookie "^(LYNX-WS-SESSIONID=[^;]*)(|; Domain=[^;]*)(|; path=[^;]*)(/)"  "$1$2$3"
        const domain = host.includes("unival.com") || host === "localhost" ? host : "lynx.collegenet.com";

        const domainForInstance = instance.toLowerCase().startsWith("qa")
            ? domain.replace("lynx.collegenet.com", "lynx-test.collegenet.com")
            : domain;
        pathAndParams = pathAndParams.startsWith("/") ? pathAndParams : "/" + pathAndParams;
        const url = `${protocol}//${domainForInstance}/lynx-ws/sis/data/${instance}/run${pathAndParams}`;

        switch (method) {
            case "post":
                return DataAccess.post(url, payload);
            case "put":
                return DataAccess.put(url, payload);
            case "get":
                return DataAccess.get(url);
        }
    }

    //https:///lynx-ws/sis/data/qabnr/run/imex.json?cmd=state&uids=LYNX-EV-106-202410-24101
    // http:///lynx-ws/sis/data/qabnr/run/imex.json?cmd=state&uids=LYNX-EV-106-202410-24101

    public static setState(alienUid: string, paused: boolean) {
        return LynxService.callLynx("post", "/imex.json?cmd=" + (paused ? "pause" : "unpause"), { uid: [alienUid] });
    }

    public static getSingleState(alienUid: string) {
        return LynxService.callLynx("get", "/imex.json?cmd=state&uids=" + alienUid).then((data: any) => {
            return S25Util.valueFind(data, "isPaused", 1);
        });
    }

    public static async createUser(username: string, lynxInstance: string, email: string, password?: string) {
        let payload = {
            object: {
                username: username,
                inst_username: username,
                is_active: 1,
                email: email,
            },
        };
        const [resp, err] = await S25Util.Maybe(
            LynxService.callLynxInstance(lynxInstance, "post", `/user.json?cmd=user`, payload),
        );

        if (err) return Promise.reject(err.error?.results?.error?.procError);

        //lynx can't set a password on user creation. So we do it here
        if (password) {
            return await LynxService.setPassword(lynxInstance, { username: username, password: password });
        } else {
            return resp;
        }
    }

    public static async setPassword(lynxInstance: string, user: { username: string; password: string }) {
        return S25Util.Maybe(
            LynxService.callLynxInstance(
                lynxInstance,
                "post",
                `/auth.json?cmd=password&username=${encodeURIComponent(user.username)}&password=${encodeURIComponent(user.password)}`,
                {},
            ),
        );
    }
}
